<template>
    <div class="btn btn--red-diamond">
        <span><slot></slot></span>
        <svg enable-background="new 0 0 118.1 31.7" viewBox="0 0 118.1 31.7" xmlns="http://www.w3.org/2000/svg">
            <path class="dia__line" d="m103.7 15.8h-33.5" style="fill:none;stroke:#b22028;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
            <path class="dia__arrow" d="m118.1 15.8-9.1-5.8v11.7z" fill="#b22028" />
            <g style="fill:none;stroke:#b22028;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                <path class="dia__square3" d="m42.3 12.5-3.3 3.3 14.8 14.9 14.8-14.9-14.8-14.8-7.2 7.2" style="fill:#fff;" />
                <path class="dia__square2a" d="m46.3 19.2 3.3-3.4-14.8-14.8-7.5 7.5" />
                <path class="dia__square2b" d="m23.3 12.5-3.4 3.3 14.9 14.9 7.2-7.3" />
                <path class="dia__square1" d="m27.3 19.2 3.4-3.4-14.9-14.8-14.8 14.8 14.8 14.9 7.3-7.3" />
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        
    }
</script>