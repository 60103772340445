import gsap, {Power3} from 'gsap'
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
gsap.registerPlugin(DrawSVGPlugin)

export default {
    data() {
        return {
            slider: null,
            tl: null,
        }
    },
    methods: {
        initSlider() {
            this.$slider = $("#slider");
            
            this.$slider.slider({
                range: false,
                min: 0,
                max: 100,
                step: 0.02,
                value: 0,
                slide: (event, ui) => {
                    this.tl.progress(ui.value / 100).pause();
                },
                stop: () => {
                    this.tl.play();
                }
            });
        },
        updateSlider() {
            this.$slider.slider("value", this.tl.progress() * 100);
        },
        initTimeline() {
            var vw = $(window).width();
            var vh = $(window).height();
            var speed = 0.85;
            this.tl = gsap.timeline({
                onUpdate: this.updateSlider,
                paused: true
            });
            this.tl.timeScale(2.7);

            // Timeline
            this.tl.fromTo($('.images__top').find('.careerDetailSvg'), speed,
                { x: 0, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                0);
                
            this.tl.add(this.getCareersDetailTopTl(), 0.2);

        },
        getCareersDetailTopTl() {
            var CareersDetailTopTl = gsap.timeline({})
            let stagger = 0.3;

            CareersDetailTopTl.fromTo($('.top-vertical'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

            CareersDetailTopTl.fromTo($('.top-horizontal'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            CareersDetailTopTl.fromTo($('.middle-line'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            CareersDetailTopTl.fromTo($('.top-arc-small'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);
            CareersDetailTopTl.fromTo($('.top-arc-large'), 1.5, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2.4);

            
            CareersDetailTopTl.fromTo($('.bottom-left'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);
            CareersDetailTopTl.fromTo($('.bottom-horizontal'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.25);
            CareersDetailTopTl.fromTo($('.bottom-right'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2);


            CareersDetailTopTl.fromTo($('.bottom-bottom'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2);

            CareersDetailTopTl.fromTo($('.image-top'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=1");
            CareersDetailTopTl.fromTo($('.image-bottom'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=1.5");
            CareersDetailTopTl.fromTo($('.bottom-mask'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=2");

            return CareersDetailTopTl;
        }
    }
}
