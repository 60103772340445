export default {

    metaInfo() {
        return {
            title: this.meta.title,
            meta: [
            {
                name: 'description',
                content: this.meta.description
            },
            {
                property: 'og:title',
                content: this.meta.title
            },
            {
                property: 'og:description',
                content: this.meta.description
            },
            {
                property: 'og:image',
                content: this.meta.image
            },
            {
                property: 'og:url',
                content: window.location.href
            }],
            //titleTemplate: 'Stanta Monica Studio - %s'
        }
    },
    data() {
        return {
            meta: {
                title: '',
                description: '',
            }
        }
    },
    methods: {
        setMetaData(data) {
            if (!data) return;
            this.meta.title = data.title;
            this.meta.image = data.image;
        }
    },
}
